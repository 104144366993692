<template>
	<v-row>
		<v-col>
			<v-card>
				<v-card-title>Principais conexões</v-card-title>
				<v-card-text v-if="carregandoSkeleton">
					<v-skeleton-loader class="mx-auto" width="100%" height="250" type="image, image"></v-skeleton-loader>
				</v-card-text>
				<v-card-text v-else>
					<!-- grafico -->
					<VueApexCharts
						ref="realtimeChart"
						type="line"
						height="250"
						:options="chartOptions"
						:series="series"
					></VueApexCharts>
					<!-- <span v-else>Nenhum registro encontrado</span> -->
				</v-card-text>
			</v-card>
		</v-col>
		<v-col cols="12">
			<v-card>
				<v-card-title>Conexões CISS</v-card-title>
				<v-divider />
				<v-card-text>
					<v-skeleton-loader v-if="carregandoSkeleton" type="table-tbody" />
					<v-simple-table v-else-if="dados.lista.length > 0">
						<thead>
							<tr>
								<th>Nome</th>
								<th>Status</th>
								<th class="text-center">Quantidade de conexões</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(dado,i) in dados.lista" :key="i">
								<td>{{dado.nome}}</td>
								<td>{{dado.status}}</td>
								<td class="text-center">{{dado.conexoes}}</td>
							</tr>
						</tbody>
					</v-simple-table>
					<div v-else class="text-center pa-2">Nenhum registro encontrado</div>
				</v-card-text>
				<v-card-actions class="px-4 my-0 py-0">
					<small
						v-if="autoRefresh && !carregando"
						class="text--secondary text-caption"
					>Próxima atualização em: {{timeRefresh}} segundos</small>
					<v-spacer />
					<span class="d-flex mb-n3">
						<v-switch
							:loading="carregando"
							v-model="autoRefresh"
							label="Atualização automática"
							color="primary"
							class="my-0 py-0"
						></v-switch>
					</span>
				</v-card-actions>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import VueApexCharts from "vue-apexcharts";

export default {
	name: "TiBancoCiss",
	components: { VueApexCharts },
	data: () => ({
		timeRefresh: 60,
		time: 5000,
		autoRefresh: true,
		carregando: false,
		carregandoSkeleton: true,
		dados: {},
		// gráfico
		series: [
			{
				name: "",
				data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
			},
			{
				name: "",
				data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
			},
			{
				name: "",
				data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
			},
			{
				name: "",
				data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
			},
			{
				name: "",
				data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
			},
		],
		chartOptions: {
			chart: {
				type: "line",
				toolbar: {
					show: false,
				},
			},
			colors: ["#002D56", "#F2B90F", "#951015"],
			dataLabels: {
				enabled: true,
			},
			markers: {
				size: 5,
			},
			stroke: {
				curve: "smooth",
			},
			yaxis: {
				show: true,
			},
			xaxis: {
				categories: [
					"00:00:00",
					"00:00:00",
					"00:00:00",
					"00:00:00",
					"00:00:00",
					"00:00:00",
					"00:00:00",
					"00:00:00",
					"00:00:00",
					"00:00:00",
					"00:00:00",
					"00:00:00",
					"00:00:00",
					"00:00:00",
					"00:00:00",
				],
			},
			legend: {
				position: "bottom",
			},
		},
	}),
	computed: {
		...mapState(["backendUrl"]),
	},
	methods: {
		listar() {
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}ti/ciss`)
				.then((res) => {
					this.dados = res.data;
					this.chartOptions.xaxis.categories.splice(0, 1);
					this.chartOptions.xaxis.categories.push(
						this.dados.lista[0].time
					);
					for (var i = 0; i < 5; i++) {
						if (this.series[i].name == "") {
							this.series[i].data.splice(0, 1);
							this.series[i].data.push(
								this.dados.lista[i].conexoes
							);
							this.series[i].name = this.dados.lista[i].nome;
						} else {
							this.series[i].data.splice(0, 1);
							this.series[i].data.push(
								parseInt(
									this.dados.lista
										.filter(
											(v) => v.nome == this.series[i].name
										)
										.map((v) => v.conexoes)
								)
							);
						}
					}
					this.carregando = false;
					this.carregandoSkeleton = false;

					if (this.autoRefresh) {
						this.timeRefresh = this.time / 1000;
						setTimeout(() => {
							this.listar(0);
							this.updateSeriesLine();
						}, this.time);
					}
				})
				.catch(() => {
					this.dados = { total: 0 };
					this.carregando = false;
					this.carregandoSkeleton = false;
				});
		},
		getRandomArbitrary() {
			return Math.floor(Math.random() * 99);
		},
		updateSeriesLine() {
			this.$refs.realtimeChart.updateSeries(
				[
					{
						name: this.series[0].name,
						data: this.series[0].data,
					},
					{
						name: this.series[1].name,
						data: this.series[1].data,
					},
					{
						name: this.series[2].name,
						data: this.series[2].data,
					},
					{
						name: this.series[3].name,
						data: this.series[3].data,
					},
					{
						name: this.series[4].name,
						data: this.series[4].data,
					},
				],
				false,
				true
			);
		},
		timerRefresh() {
			if (this.timeRefresh < 0) {
				this.timeRefresh = this.time / 1000;
			}
			setTimeout(() => {
				this.timeRefresh--;
				this.timerRefresh();
			}, 1000);
		},
		init() {
			this.timeRefresh = this.time / 1000;
			this.listar(0);
			this.timerRefresh();
		},
	},
	watch: {
		autoRefresh: function () {
			if (this.autoRefresh) {
				this.timeRefresh = this.time / 1000;
				this.listar();
			}
		},
	},
	created() {
		this.init();
	},
};
</script>

<style>
.apexcharts-tooltip {
	background: #ffffff !important;
	color: #023449;
	border-color: #023449 !important;
}

.apexcharts-tooltip-title {
	background-color: #023449 !important;
	color: #ffffff;
}
</style>
